footer{
    background-color: #ffffff;
}
ul{
    list-style-position: inside;
    padding: 0;
    margin-top: 20px;
}
ul li{
    list-style-type: none;
    margin-bottom: 10px;
   
}
ul li a{
    text-decoration: none;
    color: #000;
    font-size: 1rem;
}