.count-card, .card-solid{
    position: relative;
    width: 130px;
    height: 130px;
    border-radius: 100px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    cursor: pointer;
}
.count-card::before, .card-solid::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1.3);
    background-color: transparent;
    border-radius: 100px;
    border: 5px;
    border-color:var(--primary-color) #fff var(--primary-color) #fff;
    border-style: solid;
    z-index: -1;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

}

.count-card:hover::before, .card-solid:hover::before{
    transform: rotate(90deg) scale(1.3);
    transition: 0.6s;
}



.card-solid:hover{
    background-color:  #fff !important;
}
.card-solid:hover *{
    color: var(--primary-color) !important;
}
