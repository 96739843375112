.service-hero{
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover !important;
    background: linear-gradient(45deg, #03030325, #000000);
}

.solution-icon{
    font-size: 2rem;
    width: 60px;
    height: 60px;
    background: #ff654d27;
    color: #fff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin: auto;
    color: var(--primary-color);
}