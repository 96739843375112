:root{
  --primary-color:#2A3663;
}

.text-main{
  color: var(--primary-color) !important;
}
.main-btn{
  border: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  background: var(--primary-color);
  border-radius: 5px;
}